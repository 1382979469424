import { Layout } from 'src/deprecated/components/Layout'

const NotFoundPage = () => (
  <Layout pageBlok={null} title="Page Not Found" description="">
    <div className="container mx-auto px-2 sm:px-4">
      <div className="rounded bg-white p-4 shadow">
        <h1 className="mb-4 font-bold">Page Not Found</h1>
        <p>The page you requested was not found.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
